module.exports={
  "_args": [
    [
      {
        "raw": "datalib@^1.7.1",
        "scope": null,
        "escapedName": "datalib",
        "name": "datalib",
        "rawSpec": "^1.7.1",
        "spec": ">=1.7.1 <2.0.0",
        "type": "range"
      },
      "/Users/arvind/Research/vega2/vega3-2"
    ]
  ],
  "_from": "datalib@>=1.7.1 <2.0.0",
  "_id": "datalib@1.7.3",
  "_inCache": true,
  "_installable": true,
  "_location": "/datalib",
  "_nodeVersion": "0.12.7",
  "_npmOperationalInternal": {
    "host": "packages-16-east.internal.npmjs.com",
    "tmp": "tmp/datalib-1.7.3.tgz_1476399375564_0.8775846140924841"
  },
  "_npmUser": {
    "name": "jheer",
    "email": "jeffrey.heer@gmail.com"
  },
  "_npmVersion": "2.12.1",
  "_phantomChildren": {},
  "_requested": {
    "raw": "datalib@^1.7.1",
    "scope": null,
    "escapedName": "datalib",
    "name": "datalib",
    "rawSpec": "^1.7.1",
    "spec": ">=1.7.1 <2.0.0",
    "type": "range"
  },
  "_requiredBy": [
    "/",
    "/vega-dataflow",
    "/vega-scenegraph"
  ],
  "_resolved": "https://registry.npmjs.org/datalib/-/datalib-1.7.3.tgz",
  "_shasum": "4722715bb91f5a2411cf42bf984932cc403eba48",
  "_shrinkwrap": null,
  "_spec": "datalib@^1.7.1",
  "_where": "/Users/arvind/Research/vega2/vega3-2",
  "author": {
    "name": "Jeffrey Heer",
    "url": "http://idl.cs.washington.edu"
  },
  "browser": {
    "buffer": false,
    "fs": false,
    "http": false,
    "request": false,
    "sync-request": false,
    "url": false
  },
  "bugs": {
    "url": "https://github.com/vega/datalib/issues"
  },
  "contributors": [
    {
      "name": "Michael Correll",
      "url": "http://pages.cs.wisc.edu/~mcorrell/"
    },
    {
      "name": "Ryan Russell",
      "url": "https://github.com/RussellSprouts"
    }
  ],
  "dependencies": {
    "d3-dsv": "0.1",
    "d3-format": "0.4",
    "d3-time": "0.1",
    "d3-time-format": "0.2",
    "request": "^2.67.0",
    "sync-request": "^2.1.0",
    "topojson": "^1.6.19"
  },
  "description": "JavaScript utilites for loading, summarizing and working with data.",
  "devDependencies": {
    "browserify": "^12.0.1",
    "chai": "^3.4.1",
    "istanbul": "latest",
    "jshint": "^2.9.1-rc1",
    "mocha": "^2.3.4",
    "uglify-js": "^2.6.1"
  },
  "directories": {},
  "dist": {
    "shasum": "4722715bb91f5a2411cf42bf984932cc403eba48",
    "tarball": "https://registry.npmjs.org/datalib/-/datalib-1.7.3.tgz"
  },
  "gitHead": "0087f0a1e9e9f819bffb309fa10b4ac3c9292486",
  "homepage": "https://github.com/vega/datalib#readme",
  "keywords": [
    "data",
    "table",
    "statistics",
    "parse",
    "csv",
    "tsv",
    "json",
    "utility"
  ],
  "license": "BSD-3-Clause",
  "main": "src/index.js",
  "maintainers": [
    {
      "name": "jheer",
      "email": "jeffrey.heer@gmail.com"
    }
  ],
  "name": "datalib",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/vega/datalib.git"
  },
  "scripts": {
    "build": "browserify src/index.js -d -s dl -o datalib.js",
    "cover": "TZ=America/Los_Angeles istanbul cover _mocha -- --recursive test/",
    "deploy": "npm run test && scripts/deploy.sh",
    "lint": "jshint src/",
    "postbuild": "uglifyjs datalib.js -c -m -o datalib.min.js",
    "test": "npm run lint && TZ=America/Los_Angeles mocha --recursive test/"
  },
  "version": "1.7.3"
}
