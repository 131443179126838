module.exports = {
  axes:       require('./axes'),
  background: require('./background'),
  data:       require('./data'),
  events:     require('vega-event-selector'),
  expr:       require('./expr'),
  legends:    require('./legends'),
  mark:       require('./mark'),
  marks:      require('./marks'),
  modify:     require('./modify'),
  padding:    require('./padding'),
  predicates: require('./predicates'),
  properties: require('./properties'),
  signals:    require('./signals'),
  spec:       require('./spec'),
  streams:    require('./streams'),
  transforms: require('./transforms')
};