module.exports = {
  aggregate:    require('./Aggregate'),
  bin:          require('./Bin'),
  cross:        require('./Cross'),
  countpattern: require('./CountPattern'),
  linkpath:     require('./LinkPath'),
  facet:        require('./Facet'),
  filter:       require('./Filter'),
  fold:         require('./Fold'),
  force:        require('./Force'),
  formula:      require('./Formula'),
  geo:          require('./Geo'),
  geopath:      require('./GeoPath'),
  hierarchy:    require('./Hierarchy'),
  impute:       require('./Impute'),
  lookup:       require('./Lookup'),
  pie:          require('./Pie'),
  rank:         require('./Rank'),
  sort:         require('./Sort'),
  stack:        require('./Stack'),
  treeify:      require('./Treeify'),
  treemap:      require('./Treemap'),
  voronoi:      require('./Voronoi'),
  wordcloud:    require('./Wordcloud')
};